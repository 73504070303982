@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "VeryVogueDisplay", serif;
  font-weight: 400; /* Regular */
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* OSWALD */

@font-face {
  font-family: "Oswald-Bold";
  src: url("./assets/fonts/Oswald/Oswald-Bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Oswald-SemiBold";
  src: url("./assets/fonts/Oswald/Oswald-SemiBold.woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Oswald-Medium";
  src: url("./assets/fonts/Oswald/Oswald-Medium.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Oswald-Regular";
  src: url("./assets/fonts/Oswald/Oswald-Regular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Oswald-Light";
  src: url("./assets/fonts/Oswald/Oswald-Light.woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Oswald-ExtraLight";
  src: url("./assets/fonts/Oswald/Oswald-ExtraLight.woff2");
  font-weight: 200;
}

.oswaldBold {
  font-family: "Oswald-Bold", sans-serif;
  font-weight: 700;
}

.oswaldSemiBold {
  font-family: "Oswald-SemiBold", sans-serif;
  font-weight: 600;
}

.oswaldMedium {
  font-family: "Oswald-Medium", sans-serif;
  font-weight: 500;
}

.oswaldRegular {
  font-family: "Oswald-Regular", sans-serif;
  font-weight: 400;
}

.oswaldLight {
  font-family: "Oswald-Light", sans-serif;
  font-weight: 300;
}
.oswaldExtraLight {
  font-family: "Oswald-ExtraLight", sans-serif;
  font-weight: 200;
}

/* VERY VOGUE DISPLAY */

@font-face {
  font-family: "VeryVogueDisplay";
  src: url("./assets/fonts/VeryVogueDisplay/VeryVogueDisplay.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "VeryVogueDisplayItalic";
  src: url("./assets/fonts/VeryVogueDisplay/VeryVogueDisplayItalic.woff2");
  font-weight: 400;
}

.veryVogueDisplay {
  font-family: "VeryVogueDisplay", serif;
  font-weight: 400;
}

.veryVogueDisplayItalic {
  font-family: "VeryVogueDisplayItalic", serif;
  font-weight: 400;
}
